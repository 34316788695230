import { Injectable, EventEmitter } from "@angular/core";
import { UserManager, UserManagerSettings, User, WebStorageStateStore, } from "oidc-client-ts";
import { ConfigService } from "../config/config.service";
import { Observable, BehaviorSubject, from, of } from "rxjs";
import { Router } from "@angular/router";
import { map, take, tap, filter, catchError, throttleTime, switchMap, auditTime, withLatestFrom, } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AppState } from "src/app/@store/reducers";
import { select, Store } from "@ngrx/store";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { ResultActions } from "src/app/@store/actions/results.actions";
import { StsScopes } from "../data/auth.model";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { EStorageKeys } from "src/app/shared/model/storage.model";
import { showCookieBot } from "src/app/shared/component-modules/core/util/common.utils";
import { LoggerService } from "../logger.service";
import { UserInfo, UserRoleService, } from "src/app/plasma-ui-common/services/user-role.service";
import { UserClaimsService } from "../user-claims.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private jwt = new JwtHelperService();
  public _manager: UserManager;
  public _user: User = null;
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();

  public isLoggedIn: boolean = false;
  public profileInfo: Record<string, any> = {};
  public userInfo = new BehaviorSubject<UserInfo>(null);

  public userInfo$: Observable<UserInfo> = this.userInfo.asObservable();
  public signinRedirectTrigger$ = new BehaviorSubject<any>(null);
  public signinRedirectCallbackTrigger$ = new BehaviorSubject<any>(null);

  public signinRedirectDone$ = new BehaviorSubject(null);

  constructor(
    private config: ConfigService,
    private router: Router,
    private store: Store<AppState>,
    private logr: LoggerService,
    private readonly userClaim: UserClaimsService,
    private readonly usertype: UserRoleService
  ) {
    this._manager = new UserManager(this.getClientSettings());
    this.initializeUser();
    this.registerUserEvents();

    // subscribe to signin events
    this.signinRedirectTrigger$
      .pipe(takeUntilDestroyed(), filter(Boolean), auditTime(500))
      .subscribe((event) => this.addSignInEvents("signin"));
    this.signinRedirectCallbackTrigger$
      .pipe(takeUntilDestroyed(), filter(Boolean), auditTime(500))
      .subscribe((event) => this.addSignInEvents("signinCallback"));

    this.userClaim.claims$
      .pipe(
        takeUntilDestroyed(),
        throttleTime(100),
        filter((claims) => Boolean(claims))
      )
      .subscribe((claims) => this.saveUserInformation(claims));
  }

  public isLoggedInObs(): Observable<boolean> {
    return from(this._manager.getUser()).pipe(map(Boolean));
  }

  private getClientSettings(): UserManagerSettings {
    const { OIDC } = this.config.envJson;
    const config = this.config._envConfig;

    const DEFAULT_RESPONSE_TYPE = config.IdSrvClientResponseType;
    const DEFAULT_CLIENT_ID = config.IdSrvClientClientId;
    const DEFAULT_SCOPE = StsScopes.join(" ");

    return {
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      authority: config.baseAuthUrl,
      client_id: OIDC?.ClientId || DEFAULT_CLIENT_ID,
      redirect_uri: `${config.basePlasmaUrl}/signin-callback`,
      post_logout_redirect_uri: config.basePlasmaUrl,
      response_type: OIDC?.ResponseType || DEFAULT_RESPONSE_TYPE,
      scope: OIDC?.Scope || DEFAULT_SCOPE,
      filterProtocolClaims: true,
      loadUserInfo: false,
      silent_redirect_uri: `${config.basePlasmaUrl}/renewtoken`,
      automaticSilentRenew: true,
      revokeTokensOnSignout: true,
      monitorSession: true,
      extraQueryParams: null,
    };
  }

  private async initializeUser() {
    try {
      const user = await this._manager.getUser();
      if (user) {
        this.setUserLoggedIn(user);
      }
    } catch {
      this.isLoggedIn = false;
    }
  }

  private registerUserEvents() {
    const oidcEvent = this._manager.events;

    oidcEvent.addUserLoaded((user) => this.setUserLoggedIn(user));
    oidcEvent.addUserUnloaded(() => {
      this.isLoggedIn = false;
    });
    //this._manager.events.addUserSessionChanged(() => this.signinRedirectTrigger$.next(true));
    oidcEvent.addUserSignedOut(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.signinRedirectTrigger$.next(true);
    });
    oidcEvent.addSilentRenewError((err) => this.tokenInvalidGrant(err));
  }

  private tokenInvalidGrant(err: Error) {
    let hasInvalidGrant = err?.message?.indexOf('{"error":"invalid_grant"}');
    if (!hasInvalidGrant) {
      localStorage.clear();
      sessionStorage.clear();
      this.store.dispatch(STSActions.loadStssSuccess({ data: { token: "" } }));
      this.store.dispatch(
        ResultActions.loadCheckResults({ params: {}, isEmailUsed: true })
      );
    }
  }

  private setUserLoggedIn(user: User) {
    this._user = user;
    this.isLoggedIn = true;
    this.userLoadededEvent.emit(user);
    this.checkSavedUserInfo(user.access_token);
  }

  private checkSavedUserInfo(token: string) {
    this.store
      .pipe(
        select(StsSelectors.getSynlabId),
        filter((synlabId) => !synlabId),
        take(1),
        tap(() => this.saveToState(token))
      )
      .subscribe();
  }

  public saveToState(token: string) {
    showCookieBot(true, 500);
    const decoded = this.jwt.decodeToken(token);
    const { idp, langCode, sub } = decoded;
    this.store.dispatch(
      STSActions.loadStssSuccess({ data: { token, idp } as any })
    );

    this.userClaim.getUserClaims(sub);

    if (langCode) {
      this.userClaim.setAppLanguageFromJwtToken(langCode);
    }
  }

  async startSignoutMainWindow() {
    try {
      const user = await this._manager.getUser();
      let signOutParameters = {};

      if (user) {
        signOutParameters = {
          id_token_hint: user.id_token,
          extraQueryParams: { refresh_token: user.refresh_token },
        };
      }

      await this._manager.signoutRedirect(signOutParameters);
      localStorage.clear();
      sessionStorage.clear();
      this.logr.log("Signout success", user);
    } catch (error) {
      this.logr.log("Signout error", error);
    }
  }

  private addSignInEvents(event: "signin" | "signinCallback") {
    if (event === "signin") {
      this.handleSigninRedirect();
    }
    if (event === "signinCallback") {
      this.handleSigninRedirectCallback();
    }
  }

  private handleSigninRedirect() {
    from(this._manager.signinRedirect().catch((e) => e))
      .pipe(
        take(1),
        tap(() => this.logr.log("signinRedirect success")),
        catchError((error) => {
          this.logr.log(error);
          throw new Error("signinRedirect error");
        })
      )
      .subscribe();
  }

  private handleSigninRedirectCallback() {
    this.signinRedirectDone$.next(false);
    from(this._manager.signinRedirectCallback().catch((e) => e))
      .pipe(
        take(1),
        tap(async (user) => {
          this.logr.log("signinRedirectCallback success", user);
          document.querySelector("body")?.classList.remove("signin-redirecting");

          const initialRoute = await this.getInitialRoute();
          this.logr.log("Redirecting to initial route: ", initialRoute);

          this.router.navigateByUrl(initialRoute).then((done) => {
            this.logr.log("Redirected to initial route:", { initialRoute });
          });

          localStorage.removeItem(EStorageKeys.REDIRECT_URL);
          this.signinRedirectDone$.next(true);
        }),
        catchError((error) => {
          this.logr.log(error);
          throw new Error("signinRedirect error");
        })
      )
      .subscribe();
  }

  private async getInitialRoute(): Promise<string> {
    const redirectURL = localStorage.getItem(EStorageKeys.REDIRECT_URL);
    const redirectPath = await of(true).pipe(
       withLatestFrom(this.store.pipe(select(StsSelectors.getRedirectPath))),
       map(([_, r]) => r)
      ).toPromise();

    let initialRoute: string | string[] =
      (redirectURL?.includes("signin-callback") ? "/" : redirectURL) || "/";

    if (initialRoute === "/") {
      initialRoute = redirectPath;
    } else if (initialRoute === "/results/") {
      initialRoute = redirectPath;
    }

    if (Array.isArray(initialRoute)) {
      initialRoute = initialRoute.join("/");
    }

    return initialRoute as string;
  }

  private saveUserInformation(claims: Record<string, any>) {
    const { idp, langCode } = this.jwt.decodeToken(this._user.access_token);

    const additionalUserClaims = {
      idp,
      role: claims.user_type,
      token: this._user,
      name: `${claims.given_name} ${claims.family_name}`,
      synlabId: claims.synlab_id,
      country: claims.country,
      email: claims.email,
      given_name: claims.given_name,
      family_name: claims.family_name,
      sub: claims.sub,
      synlab_id: claims.synlab_id,
    };

    this.userInfo.next({ ...claims, idp, langCode } as any);
    this.profileInfo = { ...claims, ...additionalUserClaims };
    this.usertype.setUser(this.profileInfo);

    this.store.dispatch(
      STSActions.loadStssSuccess({ data: additionalUserClaims })
    );
  }
}
